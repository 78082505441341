import $ from 'jquery'
import './styles.scss'

$(document).ready(function() {
  dhsv_vc_stage_case()
})

$(window).on('load', function() {
  loadStage()
})

function loadStage() {
  $('.dhsv_vc_stage_case').addClass('active')
}

function dhsv_vc_stage_case() {
  $('.dhsv_vc_stage_case').each(function() {})
}
