import $ from 'jquery'

$(document).ready(function() {
  dhsv_vc_expandable_text_container()

  $('.dhsv_vc_expandable_text.expandable_text_hide').on('click', function() {
    $(this).removeClass('expandable_text_hide')
  })
})

function dhsv_vc_expandable_text_container() {
  $('.dhsv_vc_expandable_text_container').each(function() {
    $(this)
      .find('.dhsv_vc_expandable_text:nth-child(2)')
      .addClass('expandable_text_hide')
  })
}
