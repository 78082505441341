import 'jquery.cookie'

export default {
  init() {
    // JavaScript to be fired on all pages
    var $section = $('.vc_section')
    var winH = $(window).height()

    $(document).ready(function() {
      init_sections($section)
      init_headlines()
      init_smooth_scrolling_anchors()
      dhsv_footer()
      dhsv_footer_action()
      triggerMobileMenu()
      backToTop()
      landingHeroInit()
      landingHeader()
      infoBannerHandler()
      infoBannerHeight()
      gform_required_fields()
    })

    $(window).on('load', function() {
      var scrollTop = $(window).scrollTop()
      headlineShow(winH)
      ankerScroll(scrollTop)
      sectionScroll(scrollTop, winH, $section)
    })

    $(window).on('scroll', function() {
      var scrollTop = $(window).scrollTop()
      stickyHeader(scrollTop)
      sectionScroll(scrollTop, winH, $section)
      headlineShow(winH, scrollTop)
      ankerScroll(scrollTop)
      blogScroll(scrollTop)
      backToTopScroll(scrollTop, winH)
      landingHeroScroll(scrollTop, winH)
    })

    $(window).resize(function() {
      infoBannerHeight()
    })

    $('.menu-item', '.nav')
      .on('mouseenter', function() {
        let $submenu = $(this).find('.sub-menu')
        if ($submenu.length) {
          let leftEdge = $submenu.offset().left
          if (typeof leftEdge !== 'undefined' && leftEdge < 0) {
            $submenu.css({ transform: `translateX(${-leftEdge}px)` })
          }
        }
      })
      .on('mouseleave', function() {
        $(this)
          .find('.sub-menu')
          .css({ transform: '' })
      })
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
}

function bannerCSS(height) {
  $('body').css({
    'margin-top': height,
  })
  $('.banner').css({
    top: 0,
    'padding-top': height > 0 ? height + 30 : height,
  })
}

function infoBannerHeight() {
  const infoBanner = $('.infobanner')
  const body = $('body')
  if (body.hasClass('active_banner')) {
    bannerCSS(infoBanner.outerHeight())
  }
}

function infoBannerHandler() {
  const $infoBanner = $('.infobanner')
  $infoBanner.each(function() {
    const close = $(this).find('.close_banner')
    close.click(function() {
      $.cookie('infobar_rejected', 'true', { path: '/', expires: 1 })
      $('body').removeClass('active_banner')
      $infoBanner.removeClass('active')
      bannerCSS(0)
    })
  })
}

function landingHeader() {
  if ($('body').hasClass('single-landingpages')) {
    const $ankerPoints = $('.dhsv_vc_anker.point')
    const defaultmenu = $('nav.nav-primary > div .nav')
    let ankerNav = '<ul class="anker-menu-container open">'

    $ankerPoints.each(function() {
      const id = $(this)
        .find('> div')
        .attr('id')
      const title = $(this)
        .find('> div')
        .attr('data-ankername')
      ankerNav += '<li><a href="#' + id + '">' + title + '</a></li>'
    })

    const hideButton = $('article.landingpages').attr('data-button-hide')

    const button =
      hideButton == 'false'
        ? {
            text: $('article.landingpages').attr('data-button-text')
              ? $('article.landingpages').attr('data-button-text')
              : 'register now',
            link: $('article.landingpages').attr('data-button-link')
              ? $('article.landingpages').attr('data-button-link')
              : '#contact',
          }
        : false

    if (button) {
      ankerNav +=
        '<li><a href="' +
        button.link +
        '" class="cta">' +
        button.text +
        '</a></li>'
    }

    ankerNav +=
      '<li><a class="open_default-menu"><i class="icon ion-android-menu"></i></a></li>'

    ankerNav += '</ul>'

    $('nav.nav-primary > div').append(ankerNav)

    defaultmenu.append(
      '<li><a class="close_default-menu"><i class="icon ion-android-close"></i></a></li>'
    )

    $('.anker-menu-container').on('click', '.open_default-menu', function() {
      $('.anker-menu-container').removeClass('open')
      defaultmenu.addClass('open')
    })

    defaultmenu.on('click', '.close_default-menu', function() {
      $('.anker-menu-container').addClass('open')
      defaultmenu.removeClass('open')
    })
  }
}

function landingHeroInit() {
  if ($('body').hasClass('single-landingpages')) {
    //const $middleLine = $('.background_grid span:last-child')
  }
}

function landingHeroScroll(scrollTop, winH) {
  if ($('body').hasClass('single-landingpages') || $('body').hasClass('page')) {
    const $middleLine = $('#animated-line')
    $('.landing-hero').each(function() {
      const intro = $(this).find('.introsection')
      if (scrollTop > intro.offset().top - winH / 2) {
        intro.removeClass('start')
      } else {
        intro.addClass('start')
      }
    })

    $('.snap_line').each(function() {
      const $el = $(this)
      const elH = $el.height()
      const elT = $el.offset().top
      //const elWinT = $el.offset().top - scrollTop
      const compare = scrollTop - elT + winH / 2 - elH / 2

      if (-200 < compare && compare < 200) {
        $el.addClass('snapped')
        if ($el.hasClass('snapped')) {
          setTimeout(function() {
            if ($el.hasClass('snapped')) {
              $middleLine.addClass('noanim')
            }
          }, 500)
          $middleLine.addClass('snapped')
        }
      } else {
        if ($el.hasClass('snapped')) {
          $middleLine.removeClass('snapped')
          $middleLine.removeClass('noanim')
          $el.removeClass('snapped')
        }
      }

      if ($middleLine.hasClass('snapped') && $el.hasClass('snapped')) {
        $middleLine.css({
          top: elT + elH / 2,
          height: elH,
        })
      } else if (!$middleLine.hasClass('snapped')) {
        $middleLine.css(
          {
            top: scrollTop + winH / 2,
            height: '50px',
          },
          500
        )
      }
    })

    $('.vc_section').each(function() {
      const $el = $(this)
      const elH = $el.height()
      const elT = $el.offset().top
      //const elWinT = $el.offset().top - scrollTop
      const compare = scrollTop - elT + winH / 2 - elH / 2

      if (-200 < compare && compare < 200) {
        $el.addClass('snapped')
        if ($el.hasClass('snapped')) {
          setTimeout(function() {
            if ($el.hasClass('snapped')) {
              $middleLine.addClass('noanim')
            }
          }, 500)
          $middleLine.addClass('snapped')
        }
      } else {
        if ($el.hasClass('snapped')) {
          $middleLine.removeClass('snapped')
          $middleLine.removeClass('noanim')
          $el.removeClass('snapped')
        }
      }

      if ($middleLine.hasClass('snapped') && $el.hasClass('snapped')) {
        $middleLine.css({
          top: elT + elH / 2,
          //height: elH,
        })
      } else if (!$middleLine.hasClass('snapped')) {
        $middleLine.css(
          {
            top: scrollTop + winH / 2,
            height: '50px',
          },
          500
        )
      }
    })
  }
}

function backToTop() {
  $('.backtotop').click(function() {
    $('body, html').animate({
      scrollTop: 0,
    })
  })
}

function backToTopScroll(scrollTop, winH) {
  $('.backtotop').each(function() {
    if (scrollTop > winH) {
      $(this).addClass('active')
    } else {
      $(this).removeClass('active')
    }
  })
}

function blogScroll(scrollTop) {
  $('body.single-post, body.single-news').each(function() {
    var $aside = $(this).find('aside.sidebar')
    var $related = $(this).find('.dhsv-blog-item-slider')

    if (scrollTop > 250) {
      $aside.addClass('sticky')
      $aside.css('top', -110)
    } else {
      $aside.removeClass('sticky')
      $aside.css('top', 0)
    }

    if (scrollTop > 500) {
      $('header.banner').addClass('stickyblog')
    } else {
      $('header.banner').removeClass('stickyblog')
    }

    if (scrollTop > $related.offset().top - 100) {
      $('header.banner').addClass('stickyblogrelated')
    } else {
      $('header.banner').removeClass('stickyblogrelated')
    }
  })
}

function triggerMobileMenu() {
  $('.menuicon').click(function() {
    if ($(this).hasClass('active')) {
      $(this).removeClass('active')
      $(this)
        .parents('header.banner')
        .removeClass('activemenu')
      $('.mobilemenu').removeClass('open')
    } else {
      $(this)
        .parents('header.banner')
        .addClass('activemenu')
      $(this).addClass('active')
      $('.mobilemenu').addClass('open')
    }
  })

  $('.mobilemenu li a').click(function() {
    $('.menuicon').removeClass('active')
    $('header.banner').removeClass('activemenu')
    $('.mobilemenu').removeClass('open')
  })
}

function init_sections($section) {
  $section.each(function() {
    var classes = $(this).attr('class')
    var res = classes
      .replace('vc_section', '')
      .replace('padding', '')
      .replace('margin', '')
      .replace(/_none/g, '')
      .replace(/_small/g, '')
      .replace(/_medium/g, '')
      .replace(/_large/g, '')
      .replace(/ /g, '')
    if (res) {
      $(this).attr('data-body-class', res)
    }
  })
}

function init_headlines() {
  $('.dhsv_vc_headline').each(function() {
    var title = $(this).find('h1, h2, h3')
    var text = $(this).find('p')
    title.each(function() {
      var titleText = $(this).html()
      $(this).html('<span>' + titleText + '</span>')
    })
    text.addClass('subline')
  })
}

function sectionScroll(scrollTop, winH, $section) {
  $section.each(function() {
    var offset = $(this).offset()
    var classes = $(this).attr('data-body-class')
    var body = $('body').attr('data-bg')
    if (scrollTop + winH / 2 > offset.top && body !== classes) {
      $('body').attr('data-bg', classes)
    }
  })
}

function stickyHeader(scrollTop) {
  if (scrollTop > 100) {
    $('header.banner').addClass('sticky')
  } else {
    $('header.banner').removeClass('sticky')
  }
}

function headlineShow(winH, scrollTop = 0) {
  $('.dhsv_vc_headline').each(function() {
    var offset = $(this).offset()
    if (scrollTop + winH > offset.top) {
      $(this).addClass('active')
    } else {
      $(this).removeClass('active')
    }
  })
}

function ankerScroll(scrollTop) {
  var scrolled = scrollTop
  var point = $('.ankerpoint')

  point.each(function(index) {
    var element = $(this)
    var eID = element.attr('id')
    var eTop = element.offset().top

    if (
      index !== point.length - 1 &&
      point.eq(index + 1).offset().top <= scrolled + 151
    ) {
      $('[href="#' + eID + '"]')
        .parent()
        .removeClass('anker-active')
    } else {
      if (eTop <= scrolled + 151) {
        $('[href="#' + eID + '"]')
          .parent()
          .addClass('anker-active')
      } else {
        $('[href="#' + eID + '"]')
          .parent()
          .removeClass('anker-active')
      }
    }
  })
}

function init_smooth_scrolling_anchors() {
  $(document).on(
    'click',
    'a[href*="#"]:not([href="#"]):not([href*="#vc_images-carousel"]):not(.dhsv_vc_anchor_menu a):not(.collapse_ac):not([data-vc-tabs]):not([data-vc-accordion])',
    function() {
      if (
        location.pathname.replace(/^\//, '') ==
          this.pathname.replace(/^\//, '') &&
        location.hostname == this.hostname
      ) {
        var targetOffset = -150
        var target = $(this.hash)
        //var scrollTop = $(window).scrollTop()
        target = target.length
          ? target
          : $('[name="' + this.hash.slice(1) + '"]')
        if (target.length) {
          var target_pos = target.offset().top
          $('html,body').animate(
            {
              scrollTop: target_pos + targetOffset,
            },
            1000
          )
          return false
        }
      }
    }
  )
}

function dhsv_footer() {
  $('.contact_footer').each(function() {
    var form = $('.contactform')
    var formH = form.outerHeight()
    form.attr('data-height', formH)
  })

  var classes = $('.vc_section')
    .last()
    .attr('class')
  if (classes) {
    var res = classes.replace('vc_section', '')
    res = res.replace(' ', '')
    $('.contact_footer').attr('data-body-class', res)
  }

  setTimeout(function() {
    $('footer').addClass('footer-visible')
  }, 1000)
}

function dhsv_footer_action() {
  $('.contact_footer').each(function() {
    var form = $('.contactform')
    var height = form.attr('data-height')
    form.height(0)
    $(this)
      .find('.openform')
      .click(function() {
        if ($(this).hasClass('active')) {
          form.height(0)
          $(this).removeClass('active')
        } else {
          form.height(height)
          $(this).addClass('active')
        }
      })
  })
}

function gform_required_fields() {
  $('li.gfield_contains_required .ginput_container input').each(function() {
    var placeholder = $(this).attr('placeholder')
    var newplaceholder = placeholder + ' *'
    $(this).attr('placeholder', newplaceholder)
  })

  $('li.gfield_contains_required .ginput_container textarea').each(function() {
    var placeholder = $(this).attr('placeholder')
    var newplaceholder = placeholder + ' *'
    $(this).attr('placeholder', newplaceholder)
  })

  $('li.gfield_contains_required .ginput_container_consent label').each(
    function() {
      var labeltext = $(this).html()
      var newlabeltext = labeltext + ' *'
      $(this).html(newlabeltext)
    }
  )
}
