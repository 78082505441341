// import external dependencies
import 'jquery'

// import everything from autoload
import './autoload/_bootstrap.js'; import './autoload/_emptySpace.js' // eslint-disable-line

// import vc-elements javascript files
import '../vc_elements/_new/main.js'; import '../vc_elements/anker/main.js'; import '../vc_elements/blog_slider/main.js'; import '../vc_elements/button/main.js'; import '../vc_elements/cloud/main.js'; import '../vc_elements/cta/main.js'; import '../vc_elements/expandable_text_container/main.js'; import '../vc_elements/expandable_text/main.js'; import '../vc_elements/facts/main.js'; import '../vc_elements/icon_text/main.js'; import '../vc_elements/landing_big_number/main.js'; import '../vc_elements/product_compare/main.js'; import '../vc_elements/product_features/main.js'; import '../vc_elements/stage_case/main.js'; import '../vc_elements/stage_product/main.js'; import '../vc_elements/stage/main.js'; import '../vc_elements/start_video/main.js'; import '../vc_elements/tooltip/main.js' // eslint-disable-line

// import react habitat and react components
import './init/react'

// import local dependencies
import Router from './util/Router'
import common from './routes/common'
import home from './routes/home'
import aboutUs from './routes/about'

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  // Home page
  home,
  // About Us page, note the change from about-us to aboutUs.
  aboutUs,
})

// Load Events
jQuery(document).ready(() => routes.loadEvents())
