import { createSelector } from 'reselect'
import { buildQueryString } from '../../util/string'

const initialState = {
  currentSlide: 1,
  currentProduct: false,
  data: [],
  isLoading: false,
  firstPageLoaded: false,
  error: null,
  isListening: false,
}

const LOAD_POSTS = 'LOAD_POSTS'
const LOAD_POSTS_SUCCESS = 'LOAD_POSTS_SUCCESS'
const LOAD_POSTS_FAILURE = 'LOAD_POSTS_FAILURE'
const UPDATE_CURRENT_SLIDE = 'UPDATE_CURRENT_SLIDE'
const UPDATE_CURRENT_PRODUCT = 'UPDATE_CURRENT_PRODUCT'
const SET_LISTENING = 'SET_LISTENING'

export default (state = initialState, action = {}) => {
  const { type, payload } = action

  switch (type) {
    case UPDATE_CURRENT_PRODUCT:
      return {
        ...state,
        currentProduct: payload.currentProduct,
        firstPageLoaded: false,
      }
    case UPDATE_CURRENT_SLIDE:
      return {
        ...state,
        currentSlide: payload.currentSlide,
      }
    case SET_LISTENING:
      return {
        ...state,
        isListening: payload.isListening,
      }
    case LOAD_POSTS:
      return {
        ...state,
        isLoading: true,
      }
    case LOAD_POSTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        firstPageLoaded: true,
        data: payload.data,
      }
    case LOAD_POSTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
      }
    default:
      return state
  }
}

export const switchListener = () => (dispatch, getState) => {
  dispatch({
    type: SET_LISTENING,
    payload: { isListening: !getState().useCases.isListening },
  })
}

export const loadCases = (lang = 'en') => (dispatch, getState) => {
  const query = buildQueryString({
    product_id: getState().useCases.currentProduct,
    lang,
  })

  dispatch({
    type: UPDATE_CURRENT_SLIDE,
    payload: {
      currentSlide: 0,
    },
  })

  return fetch(`/wp-json/dhsv/v2/tec/use_cases/?${query}`)
    .then(response => {
      if (![200, 400].includes(response.status)) {
        const message = response.statusText
          ? response.statusText
          : response.status

        throw new Error(message)
      }

      return response.json().then(json => {
        dispatch({
          type: LOAD_POSTS_SUCCESS,
          payload: {
            data: json,
            headers: response.headers,
          },
        })

        return json
      })
    })
    .catch(error => {
      dispatch({
        type: LOAD_POSTS_FAILURE,
        payload: {
          error: error,
        },
      })
    })
}

export const sHasError = createSelector(s => s.error, error => !!error)

export const updateCurrentSlide = currentSlide => dispatch => {
  dispatch({
    type: UPDATE_CURRENT_SLIDE,
    payload: {
      currentSlide,
    },
  })
}

export const updateCurrentProduct = currentProduct => dispatch => {
  dispatch({
    type: UPDATE_CURRENT_PRODUCT,
    payload: {
      currentProduct,
    },
  })
}
