import $ from 'jquery'
import './styles.scss'

$(document).ready(function() {
  dhsv_vc_stage_product()
})

$(window).on('load', function() {
  loadStage()
})

function loadStage() {
  $('.dhsv_vc_stage_product').addClass('active')
}

function dhsv_vc_stage_product() {
  $('.dhsv_vc_stage_product').each(function() {})
}
