import $ from 'jquery'

$(document).ready(function() {
  dhsv_vc_tooltip()
})

function dhsv_vc_tooltip() {
  // $('.dhsv_vc_tooltip').each(function() {
  //   var $tooltip = $(this).find('.tooltip')
  //   $tooltip.click(function() {
  //     var link = $(this).attr('data-anchor')
  //     var target = $('.facts .item[data-key="' + link + '"]')
  //     var diff = 0
  //     var speed = 1000
  //
  //     if ($(window).width() < 768) {
  //       target.parents('.facts').slick('slickGoTo', link - 1)
  //       diff = 100
  //       speed = 500
  //     }
  //
  //     $('body, html').animate(
  //       {
  //         scrollTop: target.offset().top - diff,
  //       },
  //       speed
  //     )
  //   })
  // })
}
