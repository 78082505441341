import $ from 'jquery'

$(window).on('load', cloudLoad)
$(window).scroll(cloudLoad)

function cloudLoad() {
  var scrollTop = $(window).scrollTop()
  var winH = $(window).height()
  $('.dhsv_vc_cloud').each(function() {
    var offset = $(this).offset()
    if (scrollTop + winH / 3 * 2 > offset.top) {
      $(this).addClass('loaded')
    } else {
      $(this).removeClass('loaded')
    }
  })
}
