import React from 'react'
import PropTypes from 'prop-types'
import AnimateHeight from 'react-animate-height'

class FeaturedItem extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { selectedTermin, selectedItems, termin } = this.props

    return (
      <li
        onClick={() => this.props.onClick(termin)}
        className={
          selectedTermin === termin.id
            ? 'selected open'
            : selectedItems.includes(termin.id) ? 'selected' : ''
        }
      >
        <div
          className="imagewrapper"
          style={{ backgroundImage: 'url(' + termin.image + ')' }}
        />
        <div className="content">
          <span className="meta">
            {termin.dateLength === 'day'
              ? Object.values(termin.dateFormat.start).join('.')
              : termin.dateFormat.start.day +
                '.' +
                termin.dateFormat.start.month +
                ' - ' +
                Object.values(termin.dateFormat.end).join('.')}{' '}
            | {termin.fields.art}
          </span>
          <span className="title">{termin.title}</span>
          {termin.fields.location || termin.fields.beschreibung ? (
            <div>
              <span className="more">Mehr erfahren</span>
              <AnimateHeight
                className="expand"
                height={selectedTermin === termin.id ? 'auto' : 0}
                duration={500}
              >
                <div
                  className="location"
                  dangerouslySetInnerHTML={{ __html: termin.fields.location }}
                />
                <div
                  className="desc"
                  dangerouslySetInnerHTML={{
                    __html: termin.fields.beschreibung,
                  }}
                />
              </AnimateHeight>
            </div>
          ) : null}
        </div>
      </li>
    )
  }
}

FeaturedItem.propTypes = {
  termin: PropTypes.object,
  selectedTermin: PropTypes.number,
  selectedItems: PropTypes.array,
  onClick: PropTypes.func,
}

export default FeaturedItem
