import React from 'react'
import PropTypes from 'prop-types'
import AnimateHeight from 'react-animate-height'

class ListItem extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { selectedTermin, selectedItems, termin } = this.props

    return (
      <li
        onClick={e => this.props.onClick(termin, e)}
        className={
          selectedTermin === termin.id
            ? 'selected open'
            : selectedItems.includes(termin.id) ? 'selected' : ''
        }
      >
        <span className="meta">
          {termin.dateLength === 'day'
            ? Object.values(termin.dateFormat.start).join('.')
            : termin.dateFormat.start.day +
              '.' +
              termin.dateFormat.start.month +
              ' - ' +
              Object.values(termin.dateFormat.end).join('.')}{' '}
          | {termin.fields.art}
        </span>
        <span className="title">{termin.title}</span>
        <AnimateHeight
          className="expand"
          height={selectedTermin === termin.id ? 'auto' : 0}
          duration={500}
        >
          <div
            className="location"
            dangerouslySetInnerHTML={{ __html: termin.fields.location }}
          />
          <div
            className="desc"
            dangerouslySetInnerHTML={{ __html: termin.fields.beschreibung }}
          />
          <a download href={termin.linkIcs}>
            Termin speichern
          </a>
        </AnimateHeight>
      </li>
    )
  }
}

ListItem.propTypes = {
  termin: PropTypes.object,
  selectedTermin: PropTypes.number,
  selectedItems: PropTypes.array,
  onClick: PropTypes.func,
}

export default ListItem
