import $ from 'jquery'
import FastAverageColor from 'fast-average-color'

const fac = new FastAverageColor()

$(document).ready(function() {
  window.touch = is_touch_device()
  if (window.touch) {
    $('body').addClass('touch')
    mobileScrollVideo()
  } else {
    $('body').removeClass('touch')
    dhsvVcScrollVideo()
  }
})

//Wenn alle Inhalte geladen sind
$(window).on('load', function() {
  innerSize()
  // if ($('body').hasClass('home')) {
  //   window.location.hash = 'product-tour'
  // }
})

//Wenn die Viewport-Größe verändert wird
$(window).resize(function() {
  innerSize()
})

//Wenn die Seite gescrollt wird
$(window).scroll(function() {})

function mobileScrollVideo() {
  $('.dhsv_vc_start_video').each(function() {
    var wrapper = $(this)
    var section = wrapper.find('section[data-type="image"]')
    var arrow = wrapper.find('.arrow-load')

    if (location.hash == '#product-tour') {
      activeModal()
    } else {
      disableModal()
    }

    window.addEventListener(
      'hashchange',
      function() {
        if (location.hash == '#product-tour') {
          activeModal()
        } else {
          disableModal()
        }
      },
      false
    )

    function disableModal() {
      window.activeTour = false
      const $tour = $('.tour-modal')
      if ($tour.length) {
        $tour.removeClass('open')
      }
      $('.dhsv_vc_product_anchors, .dhsv_vc_page_anchors').removeClass('hide')
    }

    function activeModal() {
      window.activeTour = true
      $('.dhsv_vc_product_anchors, .dhsv_vc_page_anchors').addClass('hide')
      const $tour = $('.tour-modal')
      const $button = $('.dhsv_vc_stage_product .contentwrapper .btn')
      $button.addClass('loading')
      setTimeout(() => {
        $button.removeClass('loading')
        $tour.addClass('open')
      }, 1000)
    }

    $(window).resize(sectionSize(section))
    $(window).on('load', sectionSize(section))
    $(window).scroll(arrowPos)
    $(window).on('load', arrowPos)

    function arrowPos() {
      const scrollTop = $(window).scrollTop()
      const winH = $(window).height()

      arrow.css({
        top: scrollTop + winH - 15,
      })
    }

    arrow.click(function() {
      const scrollTop = $(window).scrollTop()
      let activeSection = 0
      section.each(function() {
        const top = $(this).offset().top

        if (scrollTop + 1 > top) {
          activeSection++
        }
      })

      const active = wrapper.find(
        'section[data-type="image"][data-section="' + (activeSection + 1) + '"]'
      )

      const scroll = active.offset() ? active.offset().top : wrapper.height()

      $('body, html').animate(
        {
          scrollTop: scroll,
        },
        500
      )
    })

    section.each(function() {
      const item = $(this)
      const type = $(this).attr('data-type')
      if (type === 'image') {
        const src = {
          mp4: $(this).attr('data-url-mp4'),
          ogv: $(this).attr('data-url-ogv'),
          webm: $(this).attr('data-url-webm'),
          img: $(this).attr('data-url'),
        }

        const imgType = item.attr('data-img-type')
        const output =
          imgType === 'image'
            ? '<div class="section-inner"><div class="gradient"></div><img class="stillimg" src="' +
              src.img +
              '"></div>'
            : '<div class="section-inner"><div class="gradient"></div>' +
              '<video class="stillimg" muted loop autoplay>' +
              '<source src="' +
              src.mp4 +
              '" type="video/mp4">' +
              '<source src="' +
              src.ogv +
              '" type="video/ogg">' +
              '<source src="' +
              src.webm +
              '" type="video/webm">' +
              '</video></div>'

        item.find('.imgwrapper').html(output)
      }
    })
  })
}

function sectionSize(section) {
  const winH = $(window).height()

  section.css({
    height: winH,
  })
}

function innerSize() {
  var winH = $(window).height()
  var winW = $(window).width()
  $('.dhsv_vc_start_video').each(function() {
    var sectionInner = $(this).find('section .section-inner')
    sectionSize($(this))

    if (sectionInner) {
      sectionInner.css({
        height: window.touch ? winW / 1.7777 : winH,
        width: window.touch ? winW : winH * 1.7777,
      })
    }
  })
}

function is_touch_device() {
  const winW = $(window).width()
  return winW < 600
}

function changeSwipeBehavior(state = true) {
  const wrapper = $('.dhsv_vc_start_video')[0]

  function prevent(e) {
    e.preventDefault()
  }

  if (state) {
    wrapper.addEventListener('touchstart', prevent, {
      passive: false,
    })
    wrapper.addEventListener('touchmove', prevent, {
      passive: false,
    })
  } else {
    wrapper.removeEventListener('touchstart', prevent)
    wrapper.removeEventListener('touchmove', prevent)
  }
}

function diff(num1, num2) {
  return num1 > num2 ? num1 - num2 : num2 - num1
}

function changeNav(type, nav, sectionIndex) {
  nav.find('li').removeClass('active')
  if (window.direction === 'up' && type === 'video') {
    nav.find('li:nth-child(' + (sectionIndex - 1) + ')').addClass('active')
  } else {
    nav.find('li:nth-child(' + sectionIndex + ')').addClass('active')
  }

  if (
    [0, 1].includes(window.activSection) ||
    (window.activSection === 2 && window.direction == 'up')
  ) {
    nav.addClass('hide')
  } else {
    nav.removeClass('hide')
  }
}

function dhsvVcScrollVideo() {
  var bodyHtml = $('body, html')

  $('.dhsv_vc_start_video').each(function() {
    var wrapper = $(this)
    var nav = wrapper.find('nav ul')
    var arrow = wrapper.find('.arrow-load')
    var section = wrapper.find('section')

    section.each(function() {
      const item = $(this)
      const type = $(this).attr('data-type')
      if (type === 'image' && item.find('.stillimg').length < 1) {
        const src = {
          mp4: $(this).attr('data-url-mp4'),
          ogv: $(this).attr('data-url-ogv'),
          webm: $(this).attr('data-url-webm'),
          img: $(this).attr('data-url'),
        }
        const imgType = item.attr('data-img-type')
        const output =
          imgType === 'image'
            ? '<div class="section-inner"><div class="gradient"></div><img class="stillimg" src="' +
              src.img +
              '"></div>'
            : '<div class="section-inner"><div class="gradient"></div>' +
              '<video class="stillimg" muted loop autoplay>' +
              '<source src="' +
              src.mp4 +
              '" type="video/mp4">' +
              '<source src="' +
              src.ogv +
              '" type="video/ogg">' +
              '<source src="' +
              src.webm +
              '" type="video/webm">' +
              '</video></div>'
        item.find('.imgwrapper').html(output)
      }
    })

    bodyHtml
      .animate(
        {
          scrollTop: 0,
        },
        0
      )
      .css('overflow', 'hidden')

    window.sectionCount = section.length
    window.activSection = 0
    window.videoScroll = true
    window.direction = 'down'

    changeView()

    arrow.hover(
      function() {
        nav
          .find('li[data-index="' + (window.activSection + 1) + '"]')
          .addClass('hover')
      },
      function() {
        nav.find('li').removeClass('hover')
      }
    )

    //enable/disable nav and arrow on scroll.false
    setInterval(function() {
      if (window.videoScroll) {
        nav.addClass('hover')
        arrow.addClass('hover')
      } else {
        nav.removeClass('hover')
        arrow.removeClass('hover')
      }
    }, 100)

    function getViewIndex(direction) {
      var activSection = window.activSection
      if (!(direction === 'up' && activSection === 1)) {
        var sectionCount = window.sectionCount
        window.direction = direction

        if (direction === 'up') {
          activSection = activSection > 0 ? activSection - 1 : 0
        } else {
          activSection =
            activSection < sectionCount ? activSection + 1 : sectionCount
        }

        window.activSection = activSection

        changeView()
      }
    }

    const $tour = $('.tour-modal')

    if (location.hash == '#product-tour' && $tour) {
      activeModal()
    } else {
      disableModal()
    }

    window.addEventListener(
      'hashchange',
      function() {
        if (location.hash == '#product-tour') {
          activeModal()
        } else {
          disableModal()
        }
      },
      false
    )

    function disableModal() {
      window.activeTour = false
      if ($tour.length) {
        $tour.removeClass('open')
        bodyHtml.css({
          overflow: 'unset',
          height: 'auto',
        })
        changeSwipeBehavior(false)
        window.videoScroll = false
      }
      $('.dhsv_vc_product_anchors, .dhsv_vc_page_anchors').removeClass('hide')
    }

    function activeModal() {
      window.activeTour = true
      const $button = $('.dhsv_vc_stage_product .contentwrapper .btn')
      $button.addClass('loading')
      window.activSection = 0
      window.videoScroll = true
      window.direction = 'down'
      bodyHtml.css({
        overflow: 'hidden',
        height: '100%',
      })
      changeSwipeBehavior()
      changeView()
      setTimeout(() => {
        $button.removeClass('loading')
        $tour.addClass('open')
      }, 1000)
      $('.dhsv_vc_product_anchors, .dhsv_vc_page_anchors').addClass('hide')
    }

    function changeView() {
      const { activSection, sectionCount } = window

      if (activSection !== sectionCount && window.activeTour) {
        //disable scroll on body
        bodyHtml.css({
          overflow: 'hidden',
          height: '100%',
        })
        changeSwipeBehavior()
        wrapper.removeClass('outside')

        section.each(function(index) {
          var sectionItem = $(this)
          if (index === activSection) {
            var type = sectionItem.attr('data-type')
            var sectionIndex = sectionItem.attr('data-section')
            $('#status').text(index)

            if (index === 0) {
              wrapper.addClass('changegradient')
            } else {
              wrapper.removeClass('changegradient')
            }

            if (type === 'video') {
              //start Loading
              arrow.addClass('loading-video')

              //disable scroll
              window.videoScroll = false

              //change Background for first view
              if (index === 0 && window.direction === 'down') {
                sectionItem.find('.section-inner').addClass('changegradient')
                wrapper.addClass('changegradient')
              }

              //setup video
              const urlext = window.direction === 'up' ? 'reverse-' : ''
              const url = {
                mp4: $(this).attr('data-url-' + urlext + 'mp4'),
                ogv: $(this).attr('data-url-' + urlext + 'ogv'),
                webm: $(this).attr('data-url-' + urlext + 'webm'),
              }

              sectionItem.html(
                '<div class="section-inner"><div class="gradient"></div>' +
                  '<video muted>' +
                  '<source src="' +
                  url.mp4 +
                  '" type="video/mp4">' +
                  '<source src="' +
                  url.ogv +
                  '" type="video/ogg">' +
                  '<source src="' +
                  url.webm +
                  '" type="video/webm">' +
                  '</video></div>'
              )

              const video = sectionItem.find('video')[0]

              video.play()

              //play video
              video.oncanplay = () => {
                //change nav item
                changeNav(type, nav, sectionIndex)

                //change active section
                section.removeClass('active')
                sectionItem.addClass('active')

                //stop loading
                arrow.removeClass('loading-video')
              }

              //progress arrow line for video
              video.ontimeupdate = event => {
                const dur = event.srcElement.duration
                const ct = event.srcElement.currentTime
                const percent = ct / dur * 100
                const offset = 295 / 100 * percent
                const color = fac.getColor(video, {
                  algorithm: 'dominant',
                  defaultColor: [6, 25, 35, 255],
                })
                wrapper.css({ background: color.rgb })
                sectionItem.find('.gradient').css({
                  background:
                    'linear-gradient(90deg, ' +
                    color.rgb +
                    ' 0%, transparent 5%, transparent 95%, ' +
                    color.rgb +
                    ' 100%)',
                })

                arrow
                  .find('.circle-load')
                  .css('stroke-dashoffset', 295 - offset)
              }

              //video finisch
              video.onended = () => {
                setTimeout(function() {
                  if (window.activeTour) {
                    window.videoScroll = true
                  }
                  arrow.find('.circle-load').css('stroke-dashoffset', 295)
                }, 1000)

                //update direction & activeSection
                window.direction =
                  window.activSection === 0 ? 'down' : window.direction
                if (window.direction === 'up') {
                  window.activSection = activSection > 0 ? activSection - 1 : 0
                } else {
                  window.activSection =
                    activSection < sectionCount
                      ? activSection + 1
                      : sectionCount
                }

                video.pause()

                //go to image
                changeView()
              }
            } else {
              //type=image
              section.removeClass('active')

              const image = sectionItem.find('.stillimg')[0]
              const color = fac.getColor(image, {
                defaultColor: [6, 25, 35, 255],
                algorithm: 'dominant',
              })
              wrapper.css({ background: color.rgb })
              sectionItem.find('.gradient').css({
                background:
                  'linear-gradient(90deg, ' +
                  color.rgb +
                  ' 0%, transparent 5%, transparent 95%, ' +
                  color.rgb +
                  ' 100%)',
              })

              sectionItem.addClass('active')

              changeNav(type, nav, sectionIndex)
            }
          }
        })
      } else {
        wrapper.addClass('outside')
        window.location.hash = '#'
        bodyHtml.animate(
          {
            scrollTop: 10,
          },
          0
        )
        changeSwipeBehavior(false)
        setTimeout(function() {
          bodyHtml.css({
            overflow: 'unset',
            height: 'auto',
          })
        }, 500)
      }

      innerSize()
    }

    //Handler

    //Navigation
    nav.find('li').on('click touchend', function() {
      if (window.videoScroll) {
        var liIndex = parseFloat($(this).attr('data-index'))
        var aS = window.activSection
        var difference = diff(aS, liIndex)
        window.direction = liIndex > window.activSection ? 'down' : 'up'

        //ein Schritt
        if (difference === 1 && window.direction === 'down') {
          window.activSection = liIndex === 0 ? 2 : liIndex
        } else if (difference === 3 && window.direction === 'up') {
          window.activSection = liIndex + 2
        } else {
          window.activSection = liIndex + 1 !== 0 ? liIndex + 1 : 1
        }

        changeView()
      }
    })

    //Arrow
    arrow.on('click touchend', function() {
      getViewIndex('down')
    })

    //Scroll
    window.addEventListener('wheel', function(e) {
      var scroll = window.videoScroll && window.activeTour
      if (e.deltaY < 0 && window.scrollY === 0) {
        if (scroll) {
          getViewIndex('up')
        }
      }

      if (e.deltaY > 0 && window.scrollY === 0) {
        if (scroll) {
          getViewIndex('down')
        }
      }
    })

    //Touch
    //const touchWrapper = $('.dhsv_vc_start_video')[0]

    window.client = 0
    document.addEventListener('touchstart', e => {
      const change = e.changedTouches[0]
      window.client = change.clientY
    })
    document.addEventListener('touchend', e => {
      const scroll = window.videoScroll
      const start = window.client
      const change = e.changedTouches[0]
      const scrollTop = $(window).scrollTop()

      window.direction = start < change.clientY ? 'up' : 'down'
      if (scroll && scrollTop == 0) {
        getViewIndex(window.direction)
      }
      window.client = change.clientY
    })

    $(window).scroll(function() {
      const scrollTop = $(window).scrollTop()
      const activeSection = window.activSection
      $('#status').text(scrollTop)
      if (
        scrollTop < 10 &&
        activeSection == section.length &&
        window.direction == 'up'
      ) {
        getViewIndex('up')
      }
    })

    //Keys
    document.onkeydown = checkKey

    function checkKey(e) {
      const scroll = window.videoScroll

      e = e || window.event

      if ((e.keyCode === 38 || e.keyCode === 37) && scroll) {
        getViewIndex('up')
      } else if (e.keyCode === 40 || (e.keyCode === 39 && scroll)) {
        getViewIndex('down')
      }
    }
  })
}
