import $ from 'jquery'

$(document).ready(function() {
  dhsv_vc_stage()
})

$(window).on('load', stageLoad)

$(window).scroll(function() {
  var scrollTop = $(window).scrollTop()
  var winH = $(window).height()

  if (scrollTop > winH / 4) {
    $('.dhsv_vc_stage .arrow_down').css('opacity', 0)
  } else {
    $('.dhsv_vc_stage .arrow_down').css('opacity', 1)
  }
})

function stageLoad() {
  $('.dhsv_vc_stage').addClass('loaded')
}

function dhsv_vc_stage() {
  $('.dhsv_vc_stage').each(function() {
    var winH = $(window).height()
    if ($(window).width() < 768) {
      $(this)
        .find('.imagewrapper')
        .height(winH - 150)
    } else {
      $(this).height(winH)
    }

    var scrollDown = $(this).find('.arrow_down')
    scrollDown.click(function() {
      $('body, html').animate(
        {
          scrollTop: winH,
        },
        1000
      )
    })
  })
}
