import $ from 'jquery'
import 'slick-carousel'

$(window).scroll(function() {
  dhsv_vc_facts()
})

$(document).ready(function() {
  $('.dhsv_vc_facts').each(function() {
    if ($(window).width() < 768) {
      $(this)
        .find('.facts')
        .slick({
          dots: true,
          arrows: false,
          adaptiveHeight: true,
        })
    }
  })

  init_facts()
})

function dhsv_vc_facts() {
  var scrollTop = $(window).scrollTop()
  var winH = $(window).height()
  var scrollTrigger = scrollTop + winH / 3
  $('.dhsv_vc_facts').each(function() {
    var $image = $(this).find('.imagewrapper')
    var $facts = $(this).find('.facts')
    var $items = $facts.find('.item')

    if (scrollTop + winH / 3 * 2 > $image.offset().top) {
      $image.addClass('active')
    } else {
      $image.removeClass('active')
    }

    $items.each(function() {
      var offset = $(this).offset()
      var height = $(this).height()
      var key = $(this).attr('data-key')
      if (scrollTrigger > offset.top && scrollTrigger < offset.top + height) {
        $(this).addClass('active')
        $image.find('img[data-img-count="' + key + '"]').addClass('active')
      } else {
        $(this).removeClass('active')
        $image.find('img[data-img-count="' + key + '"]').removeClass('active')
      }
    })

    if ($facts.find('.item.active').length === 0) {
      $image.find('img[data-img-count="0"]').addClass('active')
    } else {
      $image.find('img[data-img-count="0"]').removeClass('active')
    }
  })
}

function init_facts() {
  $('.dhsv_vc_facts').each(function() {
    var winH = $(window).height()
    if ($(window).width() > 768) {
      $(this)
        .find('.facts .item')
        .height(winH)
    }
  })
}
