import $ from 'jquery'

$(document).ready(function() {
  dhsv_vc_product_compare()
})

function dhsv_vc_product_compare() {
  if ($(window).width() > 768) {
    $('.dhsv_vc_product_compare').each(function() {
      var compare_item_height = 0
      var compare_item_height_elm = 0
      $('.compare-item').each(function() {
        compare_item_height_elm = $(this).outerHeight()
        if (compare_item_height_elm > compare_item_height) {
          compare_item_height = compare_item_height_elm
        }
      })
      $('.compare-item').each(function() {
        $(this).css('height', compare_item_height + 'px')
      })
    })
  }
}
