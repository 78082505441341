import React from 'react'
import PropTypes from 'prop-types'
import range from 'ramda/es/range'
import './style.scss'
import ListItem from './listItem'
import FeaturedItem from './featuredItem'
import Flickity from 'react-flickity-component'

const months = [
  'Januar',
  'Februar',
  'März',
  'April',
  'Mai',
  'Juni',
  'Juli',
  'August',
  'September',
  'Oktober',
  'November',
  'Dezember',
]
const weekDayMobile = ['MO', 'DI', 'MI', 'DO', 'FR', 'SA', 'SO']

const defaultMonth = new Date().getMonth()

class Calendar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      day: new Date().getDate(),
      month: defaultMonth,
      year: new Date().getFullYear(),
      thisMonth: [],
      date: new Date(),
      selected: [],
      selectedCal: { [defaultMonth]: [] },
      selectedItems: [],
      calSticky: 0,
      selectedTermin: null,
      today:
        new Date().getDate() +
        '' +
        new Date().getMonth() +
        '' +
        new Date().getFullYear(),
    }

    this.calWrapp = React.createRef()

    this.createMonth = this.createMonth.bind(this)
    this.nextMonth = this.nextMonth.bind(this)
    this.thisMonth = this.thisMonth.bind(this)
    this.prevMonth = this.prevMonth.bind(this)
    this.selectItem = this.selectItem.bind(this)
    this.selectCalendar = this.selectCalendar.bind(this)
    this.dayClick = this.dayClick.bind(this)
    this.handleScroll = this.handleScroll.bind(this)
  }

  componentDidMount() {
    this.createMonth()
    window.addEventListener('scroll', this.handleScroll, true)
  }

  handleScroll() {
    if (!this.calWrapp) {
      return true
    }

    const calTop = this.calWrapp.current.getBoundingClientRect().top
    const calH = this.calWrapp.current.clientHeight

    if (calTop - 100 < 0) {
      if (Math.abs(calTop - 100) < calH - 450) {
        this.setState({ calSticky: Math.abs(calTop - 100) })
      } else {
        this.setState({ calSticky: calH - 450 })
      }
    } else {
      this.setState({ calSticky: 0 })
    }
  }

  daysInMonth(month, year) {
    return new Date(year, month + 1, 0).getDate()
  }

  getDay(day) {
    const { month, year } = this.state
    return new Date(year, month, day).getDay()
  }

  getTimestamp(day, month, year) {
    return new Date(year, month, day, 0).getTime()
  }

  thisMonth() {
    const { date } = this.state
    this.setState({
      month: date.getMonth(),
      year: date.getFullYear(),
    })
  }

  nextMonth() {
    const { month, year } = this.state
    this.setState({
      month: month === 11 ? 0 : month + 1,
      year: month === 11 ? year + 1 : year,
    })
  }

  prevMonth() {
    const { month, year } = this.state
    this.setState({
      month: month === 0 ? 11 : month - 1,
      year: month === 0 ? year - 1 : year,
    })
  }

  selectItem(day) {
    this.setState({ selectedItems: [] })
    this.props.onChange(day)
    setTimeout(
      () =>
        this.setState({
          selectedItems: day.items ? day.items.map(item => item.id) : [],
          selected: [day.compare],
          selectedCal: [day.compare],
          selectedTermin: null,
        }),
      0
    )
  }

  selectCalendar(termin, e) {
    if (e.target.tagName.toLowerCase() === 'a') {
      return
    }

    const { selectedTermin } = this.state
    const yearRange = range(
      parseFloat(termin.compare.start.year),
      parseFloat(termin.compare.end.year) + 1
    )

    let monthRange = {}
    yearRange.map((y, k) => {
      if (k === 0 && k !== yearRange.length - 1) {
        monthRange = {
          ...monthRange,
          [y]: range(parseFloat(termin.compare.start.month), 12),
        }
      } else if (k !== 0 && k !== yearRange.length - 1) {
        monthRange = {
          ...monthRange,
          [y]: range(0, 12),
        }
      } else if (k !== 0 && k === yearRange.length - 1) {
        monthRange = {
          ...monthRange,
          [y]: range(0, parseFloat(termin.compare.end.month) + 1),
        }
      } else {
        monthRange = {
          ...monthRange,
          [y]: range(
            parseFloat(termin.compare.start.month),
            parseFloat(termin.compare.end.month) + 1
          ).map(i => i),
        }
      }
    })

    let terminRange = {}

    yearRange.map(y => {
      monthRange[y].map((m, k) => {
        const days = this.daysInMonth(m, y)
        if (termin.compare.start.year !== termin.compare.end.year) {
          if (parseFloat(termin.compare.start.year) === y) {
            terminRange[y] = {
              ...terminRange[y],
              [m]: range(
                parseFloat(termin.compare.start.day),
                parseFloat(days) + 1
              ).map(i => i + '' + m + '' + y),
            }
          } else {
            terminRange[y] = {
              ...terminRange[y],
              [m]: range(1, parseFloat(termin.compare.end.day) + 1).map(
                i => i + '' + m + '' + y
              ),
            }
          }
        } else {
          if (k === 0 && k !== monthRange[y].length - 1) {
            terminRange[y] = {
              ...terminRange[y],
              [m]: range(
                parseFloat(termin.compare.start.day),
                parseFloat(days) + 1
              ).map(i => i + '' + m + '' + y),
            }
          } else if (k !== 0 && k !== monthRange[y].length - 1) {
            terminRange[y] = {
              ...terminRange[y],
              [m]: range(1, parseFloat(days) + 1).map(i => i + '' + m + '' + y),
            }
          } else if (k !== 0 && k === monthRange[y].length - 1) {
            terminRange[y] = {
              ...terminRange[y],
              [m]: range(1, parseFloat(termin.compare.end.day) + 1).map(
                i => i + '' + m + '' + y
              ),
            }
          } else {
            terminRange[y] = {
              ...terminRange[y],
              [m]: range(
                parseFloat(termin.compare.start.day),
                parseFloat(termin.compare.end.day) + 1
              ).map(i => i + '' + m + '' + y),
            }
          }
        }
      })
    })

    this.setState({
      selectedItems: [],
      selected: [],
      selectedTermin: selectedTermin === termin.id ? null : termin.id,
      selectedCal: selectedTermin === termin.id ? [] : terminRange,
    })
  }

  between(x, min, max) {
    return x >= min && x <= max
  }

  getDayItems(timestamp) {
    const { termine } = this.props
    return termine.filter(termin => {
      const eventStartTimestamp = new Date(
        termin.compare.start.year,
        termin.compare.start.month,
        termin.compare.start.day,
        0
      ).getTime()
      const eventEndTimestamp = new Date(
        termin.compare.end.year,
        termin.compare.end.month,
        termin.compare.end.day,
        0
      ).getTime()
      return this.between(timestamp, eventStartTimestamp, eventEndTimestamp)
    })
  }

  dayClick(day) {
    switch (day.click) {
      case 'SELECT_ITEM':
        this.selectItem(day)
        break
      case 'PREV_MONTH':
        this.prevMonth()
        break
      case 'NEXT_MONTH':
        this.nextMonth()
        break
    }
  }

  createMonth() {
    const { month, year, selected, selectedCal } = this.state
    let newMonth = []
    const monthCount = this.daysInMonth(month, year)
    const dayOne = this.getDay(1) === 0 ? 7 : this.getDay(1)

    //Last Month
    for (let i = dayOne - 2; i >= 0; i--) {
      const newYear = month - 1 === 0 ? year - 1 : year
      const compare = i + '' + (month - 1) + '' + year
      const timestamp = this.getTimestamp(i, month - 1, newYear)
      const timestampToday = getTodayTimestamp()
      const day = {
        day: this.daysInMonth(month - 1, newYear) - i,
        month: month - 1,
        timestamp: timestamp,
        compare: compare,
        disabled: true,
        past: timestampToday > timestamp,
        today: timestamp === timestampToday,
        selected: selected.includes(compare),
        terminSelected:
          year in selectedCal && month in selectedCal[year]
            ? selectedCal[year][month].includes(compare)
            : false,
        click: 'PREV_MONTH',
        items: this.getDayItems(timestamp),
      }

      newMonth = [...newMonth, day]
    }

    //This Month
    for (let i = 1; i <= monthCount; i++) {
      const compare = i + '' + (month - 1) + '' + year
      const timestamp = this.getTimestamp(i, month, year)
      const timestampToday = getTodayTimestamp()

      const day = {
        day: i,
        month: month,
        disabled: false,
        compare: compare,
        timestamp: timestamp,
        today: timestamp === timestampToday,
        past: timestampToday > timestamp,
        selected: selected.includes(compare),
        terminSelected:
          year in selectedCal && month in selectedCal[year]
            ? selectedCal[year][month].includes(compare)
            : false,
        click: 'SELECT_ITEM',
        items: this.getDayItems(timestamp),
      }

      newMonth = [...newMonth, day]
    }

    //NextMonth
    if (this.getDay(monthCount - 1) !== 6) {
      for (let i = 1; i < 7 - this.getDay(monthCount - 1); i++) {
        const compare = i + '' + (month - 1) + '' + year
        const timestamp = this.getTimestamp(i, month + 1, year)
        const timestampToday = getTodayTimestamp()
        const day = {
          day: i,
          month: month + 1,
          disabled: true,
          timestamp: timestamp,
          compare: compare,
          past: timestampToday > timestamp,
          today: timestamp === timestampToday,
          selected: selected.includes(compare),
          terminSelected:
            year in selectedCal && month in selectedCal[year]
              ? selectedCal[year][month].includes(compare)
              : false,
          click: 'NEXT_MONTH',
          items: this.getDayItems(timestamp),
        }
        newMonth = [...newMonth, day]
      }
    }

    return newMonth
  }

  render() {
    const { termine, customcss, options, title } = this.props
    const {
      day: today,
      month,
      year,
      selectedTermin,
      selectedItems,
      calSticky,
    } = this.state
    let noEvents = options.elements.includes('calendar')
      ? 'Keine Termine in diesem Monat'
      : 'Keine passenden Termine vorhanden'
    let featured = []
    let list = []

    if (!options.elements.includes('calendar')) {
      list = termine
        .filter(termin => {
          const {
            day: endDay,
            month: endMonth,
            year: endYear,
          } = termin.compare.end
          return (
            this.getTimestamp(today, month, year) <=
            this.getTimestamp(endDay, endMonth, endYear)
          )
        })
        .sort(function(a, b) {
          return a.dateStart - b.dateStart
        })
    } else {
      list = termine
        .filter(
          termin =>
            month >= termin.compare.start.month &&
            month <= termin.compare.end.month &&
            (year >= termin.compare.start.year &&
              year <= termin.compare.end.year)
        )
        .sort(function(a, b) {
          return a.dateStart - b.dateStart
        })
    }

    if (options.elements.includes('featured')) {
      featured = list.filter(termin => termin.fields.featured)
      list = list.filter(termin => !termin.fields.featured)
    }

    if (options.type) {
      featured = featured.filter(termin => {
        return termin.fields.typ
          ? termin.fields.typ.includes(options.type)
          : false
      })
      list = list.filter(termin => {
        return termin.fields.typ
          ? termin.fields.typ.includes(options.type)
          : false
      })
    }

    if (!options.elements.includes('calendar')) {
      list = list.slice(0, 5)
    }

    const flickityOptions = {
      cellSelector: '.featured-item',
      draggable: featured.length > 1 ? true : false,
      pageDots: featured.length > 1 ? true : false,
      prevNextButtons: false,
      contain: true,
      freeScroll: false,
      autoPlay: false,
      pauseAutoPlayOnHover: true,
    }

    return (
      <div
        className={
          'dhsv_calendar' +
          (options.align ? ' ' + options.align : '') +
          (customcss ? ' ' + customcss : '')
        }
        ref={this.calWrapp}
      >
        {list.length > 0 && title ? (
          <h4>
            {title} | <a href="/termine/">Zum Terminkalender</a>
          </h4>
        ) : null}
        {options.elements.includes('calendar') ? (
          <div
            className="calendar"
            style={{ transform: `translateY(${calSticky}px)` }}
          >
            <div className="calendar-nav">
              <button onClick={this.prevMonth}>
                <i className="ion-ios-arrow-left" />
              </button>
              <span className="this-month">
                <i className="ion-android-calendar" onClick={this.thisMonth} />{' '}
                {months[month]} {year}
              </span>
              <button onClick={this.nextMonth}>
                <i className="ion-ios-arrow-right" />
              </button>
            </div>
            <ul>
              {weekDayMobile.map((day, k) => (
                <li key={k} className="mobile title">
                  {day}
                </li>
              ))}
              {this.createMonth().map((day, k) => (
                <li
                  key={k}
                  className={
                    'day' +
                    (day.disabled ? ' disable' : '') +
                    (day.past ? ' past' : '') +
                    (day.today ? ' today' : '') +
                    (day.selected ? ' selected' : '') +
                    (day.terminSelected ? ' termin-selected' : '') +
                    (day.items.length > 0 ? ' has-event' : '')
                  }
                  onClick={() => this.dayClick(day)}
                >
                  {day.day}
                </li>
              ))}
            </ul>
          </div>
        ) : null}
        {options.elements.includes('featured') ||
        options.elements.includes('list') ? (
          <div className="list">
            {options.elements.includes('featured') ? (
              <Flickity
                className={'flickity'}
                elementType={'div'}
                options={flickityOptions}
                disableImagesLoaded={false}
                reloadOnUpdate={false}
              >
                {featured.map(termin => (
                  <div className="featured-item" key={termin.id}>
                    <FeaturedItem
                      onClick={() => this.selectCalendar(termin)}
                      termin={termin}
                      selectedItems={selectedItems}
                      selectedTermin={selectedTermin}
                    />
                  </div>
                ))}
              </Flickity>
            ) : null}
            {options.elements.includes('list') ? (
              list.length > 0 ? (
                <ul>
                  {list
                    .sort(function(a, b) {
                      return a.dateStart - b.dateStart
                    })
                    .slice(
                      0,
                      !options.elements.includes('calendar') ? 5 : list.length
                    )
                    .map(termin => (
                      <ListItem
                        key={termin.id}
                        onClick={this.selectCalendar}
                        termin={termin}
                        selectedItems={selectedItems}
                        selectedTermin={selectedTermin}
                      />
                    ))}
                </ul>
              ) : (
                <div className="noevents">{noEvents}</div>
              )
            ) : null}
          </div>
        ) : null}
      </div>
    )
  }
}

const getTodayTimestamp = () => {
  const today = new Date()
  today.setHours(0, 0, 0, 0)
  return today.getTime()
}

Calendar.defaultProps = {
  termine: [],
}

Calendar.propTypes = {
  isMobile: PropTypes.bool,
  title: PropTypes.string,
  termine: PropTypes.array,
  options: PropTypes.object,
  customcss: PropTypes.string,
  onChange: PropTypes.func,
}

export default Calendar
